import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image";
import { checkout } from '../api/stripe';
import { publicKey } from '../constants/stripe';
import { annualPayment } from '../constants/stripe';
import { monthlyPayment } from '../constants/stripe';
import Auth from "../components/Auth";
import "../components/layout.scss";
import styled from "styled-components";


const WidgetSection = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  top: 200;
`;

const PriceSelector = styled.div`
  background-color: '#b4adbe',
  width: 30%;
  display: inline-block;
  overflow: hidden;
`;

const PlanInfoSection = styled.div`
  background-color: #fef9f4;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-top: 100px;
`;

const Title = styled.div`
    font-family: "Schoolbell", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 3.4px;
    color: #ff4880;
    text-transform: uppercase;
`;

const SubTitle = styled.div`
    font-family: 'Fredoka One', sans-serif;
    font-weight: 400;
    font-size: 40px;
    color: #271344;
`;

const PlanDuration = styled.div`
    font-family: 'Fredoka One', sans-serif;
    font-weight: 400;
    font-size: 28px;
    color: #271344;
`;

const EnrollmentSectionText = styled.div`
    max-width: 500px;
    padding: 0px 20px;
    margin: auto;
`;


const ExtraPaddingBottom = styled.div`
  padding-bottom: 30px;
`;

export const query = graphql`
  {
    pricing: allContentfulPricing {
      nodes {
        annualPricing
        annualPriceTitle
        monthlyPricingTitle
        annuallyDescription {
          annuallyDescription
        }
        lastSectionHeading
        message {
          message
        }
        monthlyDescription {
          monthlyDescription
        }
        monthlyPricing
        subHeading
        title
      }
    }
  }
`;

const Pricing = ({ data }) => {

  const [loader, setLoader] = useState(false);
  const { pricing: { nodes } } = data;

  const {
    annualPricing,
    annualPriceTitle,
    annuallyDescription: { annuallyDescription },
    lastSectionHeading,
    message: { message },
    monthlyDescription: { monthlyDescription },
    monthlyPricing,
    monthlyPricingTitle,
    subHeading,
    title
  } = nodes[0];

  let monthlyListItems = []
  let annualListItems = []
  let monthlyDetailPara
  let annualDetailPara

  try {
    let perMonth = JSON.parse(monthlyDescription);
    let contents = [...perMonth.content]

    contents.forEach(item => {
      if (item.content.length) {
        item.content.forEach(level1 => {
          let contArr = []
          if (level1.content && level1.content.length) {
            level1.content.forEach(level2 => {
              if (level2.content && level2.content.length) {
                level2.content.forEach(level3 => {
                  if (level3.marks.length && level3.marks[0].type === 'bold') {
                    contArr.push(`<b>${level3.value}</b>`)
                  } else {
                    contArr.push(level3.value)
                  }
                })
              } else { }
            })
          } else {
            if (level1.value) {
              if (level1.marks.length && level1.marks[0].type === 'bold') {
                monthlyDetailPara = `<b>${level1.value}</b>`
              } else {
                monthlyDetailPara = level1.value
              }
            }
          }

          if (level1.nodeType === 'list-item') {
            let str = contArr.join("")
            monthlyListItems.push(str)
          }

        })

      }
    })

  } catch (err) {
    // nothing
  }

  try {
    let perAnnum = JSON.parse(annuallyDescription);
    let contents = [...perAnnum.content]

    contents.forEach(item => {
      if (item.content.length) {
        item.content.forEach(level1 => {
          let contArr = []
          if (level1.content && level1.content.length) {
            level1.content.forEach(level2 => {
              if (level2.content && level2.content.length) {
                level2.content.forEach(level3 => {
                  if (level3.marks.length && level3.marks[0].type === 'bold') {
                    contArr.push(`<b>${level3.value}</b>`)
                  } else {
                    contArr.push(level3.value)
                  }
                })
              } else { }
            })
          } else {
            if (level1.value) {
              if (level1.marks.length && level1.marks[0].type === 'bold') {
                annualDetailPara = `<b>${level1.value}</b>`
              } else {
                annualDetailPara = level1.value
              }
            }
          }

          if (level1.nodeType === 'list-item') {
            let str = contArr.join("")
            annualListItems.push(str)
          }

        })

      }
    })

  } catch (err) {
    // nothing
  }

  const handleCreateCheckoutSession = async (priceId) => {
    setLoader(true);
    const email = localStorage.getItem("email");
    const data = await checkout({ priceId, customerEmail: email });
    const stripe = await loadStripe(publicKey);
    await stripe.redirectToCheckout(data);
    setLoader(false);
  }
  let [paymentPlan, setPaymentPlan] = useState('annually');

  const renderPricing = (
    <Layout>
      <div id="content" className="site-content" style={{}}>
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <div
                            className="elementor-section elementor-top-section elementor-element elementor-element-06481c4 elementor-section-stretched elementor-section-full_width elementor-section-height-min-height elementor-section-items-top elementor-section-height-default"
                            data-id="06481c4"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                            style={{ background: "none" }}
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c1fe0d6"
                                data-id="c1fe0d6"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-811492b elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="811492b"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-21ccc75"
                                        data-id="21ccc75"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-04219a5 elementor-widget elementor-widget-cms_image_single"
                                            data-id="04219a5"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-left-to-right wow pulse">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="80"
                                                    height="80"
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <WidgetSection>
                                    <Title>{subHeading}</Title>
                                    <SubTitle>{title}</SubTitle>
                                    <EnrollmentSectionText>{message}</EnrollmentSectionText>
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        className="elementor-element elementor-element-2ded355 elementor-widget elementor-widget-cms_cta"
                                        data-id="2ded355"
                                        data-element_type="widget"
                                        data-widget_type="cms_cta.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="cms-cta layout1 ">
                                            <div className="inner-cms-cta">
                                              <div className="col-content"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <PriceSelector>
                                      <div className='plan-price-container'>
                                        <div
                                          onClick={() => { setPaymentPlan('annually') }}
                                          className={paymentPlan === 'annually' ? 'plan-price-active' : 'plan-price'} >
                                          <p className={paymentPlan === 'annually' ? 'plan-price-text-active' : 'plan-price-text'}>Annually</p>
                                        </div>
                                        <div
                                          onClick={() => { setPaymentPlan('monthly') }}
                                          className={paymentPlan === 'monthly' ? 'plan-price-active' : 'plan-price'} >
                                          <p className={paymentPlan === 'monthly' ? 'plan-price-text-active' : 'plan-price-text'}>Monthly</p>
                                        </div>
                                      </div>
                                    </PriceSelector>

                                    <PlanInfoSection>
                                      <div className='plan-info'>
                                        <PlanDuration>{paymentPlan === 'monthly' ? annualPriceTitle : monthlyPricingTitle}</PlanDuration>
                                        <h5><b>$ {paymentPlan === 'monthly' ? monthlyPricing : annualPricing}/mo</b></h5>

                                        {
                                          (paymentPlan === 'monthly' ? <p className="mb-0" style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: monthlyDetailPara }} /> : <p style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: annualDetailPara }} className="mb-0" />)
                                        }

                                        <ul>
                                          {
                                            (paymentPlan === 'monthly' ? monthlyListItems : annualListItems).map((desc, index) => {
                                              return <li key={index.toString()} dangerouslySetInnerHTML={{ __html: desc }} />
                                            })
                                          }
                                        </ul>

                                        <button
                                          disabled={loader ? true : false}
                                          onClick={() => handleCreateCheckoutSession(paymentPlan === 'annually' ? annualPayment : monthlyPayment)}
                                          class="plan-button">
                                          {loader ? '' : lastSectionHeading}
                                          {loader && (
                                            <Spinner
                                              animation="border"
                                              as="span"
                                              animation="border"
                                              size="sm"
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </PlanInfoSection>

                                  </WidgetSection>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Layout>
  )

  return <Auth>{renderPricing}</Auth>
}

export default Pricing;
